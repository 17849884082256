<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信贷产品详情</div>
    <p class="title">产品基本信息</p>
    <div class="card">
        <div class="product-header flex-between">
              <img :src="store.state.imgURL + routerObj.bankImgUrl"> 
              <p class="gray">放款<span>{{routerObj.loanedCnt ? routerObj.loanedCnt : 0}}</span>笔</p>
        </div>
        <img class="product-img" :src="store.state.imgURL+productMap.imgUrl" alt="">
        <div class="product-name flex-between">
            <p class="flex1">{{productMap.creditName}}</p>
            <div class="star flex">
              <img src="../../assets/img/star.png" alt="">
              <img src="../../assets/img/star.png" alt="">
              <img src="../../assets/img/star.png" alt="">
              <img v-show="parseInt(routerObj.loanedCnt) > 0" src="../../assets/img/star.png" alt="">
              <img v-show="parseInt(routerObj.loanedCnt) > 5" src="../../assets/img/star.png" alt="">
            </div>
        </div>
        <div class="product-btn flex">
            <div class="share flex" @click="showShare = true">
              <img src="../../assets/img/share.png" alt="">
              <p>分享</p>
            </div>
             <div class="collect flex" @click="collect(routerObj.productId,productMap.cancelCollectFlag)">
                 <van-icon :name="productMap.cancelCollectFlag==='0'?'star':'star-o'"/><span>收藏</span>
             </div>
             <div class="compare flex" @click="contrast(productMap.productId)">
                 <img src="../../assets/img/duibi_icon.png" alt="">
                 <p>对比</p>
             </div>
        </div>
        <div class="catalog">
            <p>产品编号</p>
            <p>{{productMap.productId}}</p>
        </div>
        <div class="catalog">
            <p>产品适用银行</p>
            <p>{{productMap.bankName}}</p>
        </div>
        <div class="catalog">
            <p>产品类型</p>
            <p>{{ replaceCredit(productMap.creditType) }}</p>
        </div>   
        <div class="catalog">
            <p>产品类型描述</p>
            <p>{{productMap.creditDescr}}</p>
        </div>
        <div class="catalog">
            <p>适用对象</p>
            <p>{{ replaceUser(productMap.userType) }}</p>
        </div>
        <div class="catalog">
            <p>担保方式</p>
            <p>{{ replaceGua(productMap.guaType) }}</p>
        </div>
        <div class="catalog">
            <p>币种</p>
            <p>{{productMap.currencyType}}</p>
        </div>
        <div class="catalog">
            <p>申办条件</p>
            <p>{{productMap.appCondition}}</p>            
        </div>
        <div class="catalog">
            <p>产品简介</p>
            <p>{{productMap.creProfile}}</p>
        </div>
         
    </div>
    <p class="title">融资信息</p>
    <div class="card">
        <div class="catalog">
            <p>利率水平</p>
            <p v-if="productMap.rate">{{ productMap.maxRate ? '浮动幅度'+productMap.minRate+'%至'+productMap.maxRate + '%': '浮动幅度'+productMap.minRate+'%至'+productMap.minRate + '%'}}</p>
            <p v-else>{{ productMap.rateOtherContent}}</p>
        </div>
        <div class="catalog">
            <p>融资额度</p>
            <p>{{productMap.minLimitAmt ? productMap.minLimitAmt+'~'+productMap.maxLimitAmt + '万': '无限制'}}</p>
        </div>
        <div class="catalog">
            <p>办理流程</p>
            <p>{{productMap.maneProcess}}</p>
        </div>
        <div class="catalog">
            <p>融资期限</p>
            <p>{{productMap.minYears ? productMap.minYears+'~'+productMap.maxYears + '个月': '无限制'}}</p>
        </div>
        <div class="catalog">
            <p>需提交材料</p>
            <p>{{productMap.subData}}</p>
        </div>
        <div class="catalog">
            <p>其他说明</p>
            <p>{{productMap.otherDescr}}</p>
        </div>
        <div class="catalog">
            <p>更新时间</p>
            <p>{{productMap.updTime}}</p>
        </div>
    </div>
    <div class="footer-btn">
        <div class="yellowc" @click="goApply()">需求申请</div>
        <button class="yellowt" @click="goBack">返回</button>
    </div>
     <van-share-sheet
  v-model:show="showShare"
  title="立即分享给好友"
  :options="shareOptions"
  @select="onSelect"
/>
  </div>
  <van-popup v-model:show="showQR">
    <QrcodeVue id="qrcode" :value="showQRurl" :size="256" style="padding: 5px"></QrcodeVue>
    <div class="downImg" @click="downloadAppCodeImg">点击下载二维码</div>
  </van-popup>
</template>
<script>
import { ref,reactive,toRaw,toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { apiProductDetail,replaceCredit,replaceUser,replaceGua,apiCollect } from '../../api/axios';
import { Toast,Dialog} from 'vant';
import useClipboard from 'vue-clipboard3';
import QrcodeVue from 'qrcode.vue';

export default {
   setup() {
    let router = useRouter();
    let store = useStore();
    const state = reactive({
        productMap: {},
        routerObj: {}
    });
    let rObj = decodeURIComponent(router.currentRoute.value.query.proIds);
    state.routerObj = JSON.parse(rObj);
    function getApiProductDetail() {
        
          apiProductDetail({proId: state.routerObj.productId}).then(res => {                 
             if(res.code == '1'){
                // page = res.info.page;
                // if(apiProductListInfo.isFirst == '1') {
                //   store.dispatch('setQueryInfo',res.info.queryInfo);
                // } 
                state.productMap = res.info.productMap;
                console.log(res.info,8520);       
              }
              else {
                Toast(res.msg);
              }
          }).catch(err=>{
              console.log(err,'请求错误') 
          })
      }
    getApiProductDetail();
    const showShare = ref(false);
    const showQR = ref(false);
    const showQRurl = ref('http://yhapp.gaoliuxu.com/#/');
    const shareOptions = [
    //   { name: '微信', icon: 'wechat' },
    //   { name: '微博', icon: 'weibo' },
      { name: '复制链接', icon: 'link' },
      { name: '二维码', icon: 'qrcode' },
    ];
    const onSelect = (option) => {
      let urls = window.location.href;
      if(option.name==='复制链接') {
        copy(urls);//复制到剪切板
        showShare.value = false;
      }else {
        showShare.value = false;
        showQRurl.value = urls;
        showQR.value = true;               
      }  
    };
    
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
      	//复制
        await toClipboard(Msg)
        // console.log(Msg)
        Toast('已复制到剪切板');
      } catch (e) {
      	//复制失败
        // console.error(e)
        Toast('复制失败');
      }
    }

    const goApply=() => {
        if(store.state.token) {
            router.push({
                name:'apply',
                query: {proIds: state.routerObj.productId}
            });
          }else {
            Dialog.confirm({
              title: '提示！',
              confirmButtonText: '去登录',
              message:
                '您还没有登录！点击下方按钮去登录。',
              })
              .then(() => {
                router.push({path: '/login'});
              })
              .catch(() => {
                console.log('取消登录!')
              });
          }
        
    };
    // 收藏
    const collect = (id,flag) => {
        //   console.log(id,flag)
         let obj = {
          proId: id,
          cancelCollectFlag: '0',
        }   
        if(store.state.token) {             
            if(flag === '0') {
            obj.cancelCollectFlag = '1';
            }
            apiCollect(obj).then(res => {                 
                if(res.code == '1'){
                    state.productMap.cancelCollectFlag = obj.cancelCollectFlag;  
                }
                else {
                    Toast(res.msg);
                }
            });

          }else {
            Dialog.confirm({
              title: '提示！',
              confirmButtonText: '去登录',
              message:
                '您还没有登录！点击下方按钮去登录。',
              })
              .then(() => {
                router.push({path: '/login'});
              })
              .catch(() => {
                console.log('取消登录!')
              });
          }
   
    };
    //对比
    let listCon = store.state.contrastList;
    const contrast = (id) => {
        if(listCon.length < 3){
          let arrIndex = listCon.findIndex(item => {
            return item.productId === id
          });
          if (arrIndex > -1) {
            Toast('已经在对比列表中啦');
          } else {
              let objs = {
                  productId: state.productMap.productId,
                  creditName: state.productMap.creditName,
                  orgName: state.productMap.bankName,
              }
            store.dispatch('setContrastList',objs);
            Toast('已加入');
          }          
        }else {
          Toast('最多只能对比3个');
        }        
      };
    const downloadAppCodeImg = ()=>{
       let canvas = document.getElementById('app').getElementsByTagName('canvas')
      //创建a标签
      let a = document.createElement('a')
      //获取二维码的url并赋值为a.href
      a.href = canvas[0].toDataURL('img/png')
      //设置下载文件的名字
      a.download = '二维码.png'
      //点击事件，相当于下载
      a.click()
    }
    return {
        store,
      ...toRefs(state),
      shareOptions,
      onSelect,
      showShare,
      showQR,
      showQRurl,
      goApply,
      replaceCredit,
      replaceUser,
      replaceGua,
      collect,
      contrast,
      downloadAppCodeImg
     };
  },
  components: {
    QrcodeVue
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    .card {
        padding: 2vw 4vw;
        .product-header {
            img {
                width: 35vw;
            }
            .gray {
                font-size: 4vw;
                color: #666666;
                span {
                    color: #ff9900;
                }
            }
        }
        img.product-img {
            width: 100%;
            height: auto;
            margin: 2vw 0;
        }
        .product-name {
            .flex1 {
                font-size: 4.8vw;
                color: #6699ff;
                font-weight: 600;
            }
            .star {
                img {
                  width: 4vw;
                  height: 4vw;
                  margin-left: 1vw;
                }
            }
        }
        .product-btn {
            padding: 3vw 0;
            border-bottom: 1px solid #f5f5f5;
            .share {
                border: 2px solid #ff9900;
                padding: 1vw 3vw;
                border-radius: 4vw;
                img {
                    width: 3.6vw;
                    height: auto;
                    margin-right: 1vw;
                }
                p {
                    color: #ff9900;
                    font-size: 3.2vw;
                }
            }
            .collect {
                font-size: 4vw;
                color: #0099ff;
                border: 2px solid #0099ff;
                padding: 1vw 3vw;
                border-radius: 4vw;
                margin-left: 2vw;
                span {
                font-size: 3.2vw;
                color: #0099ff;
                margin-left: 1vw;
                }
            }
            .compare {
                border: 2px solid #ff6666;
                padding: 1vw 3vw;
                border-radius: 4vw;
                margin-left: 2vw;
                img {
                    width: 3.6vw;
                    height: auto;
                    margin-right: 1vw;
                }
                p {
                font-size: 3.2vw;
                color: #ff6666;
                }
            }
        }

        .catalog {
            border-bottom: 1px solid #f5f5f5;
            padding: 3vw 0;
            display: flex;
            p:first-child {
                flex:1;
                color: #666666;
                font-size: 4vw;
            }
            p:last-child {
                flex:2;
                font-size: 4vw;
                padding-left: 4vw;
                word-break: break-all;
            }
        }
        .catalog:last-child {
            border-bottom: 0;
        }
    }
    .footer-btn {
        display: flex;
        justify-content: center;
        .yellowc {
            font-size: 4vw;
            width: 26vw;
            padding: 1vw;
            border-radius: 6vw;
            margin: 4vw 2vw; 
            border: 0;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            text-align: center;
        }
        .yellowt {
            font-size: 4vw;
            width: 26vw;
            padding: 1vw;
            border-radius: 6vw;
            margin: 4vw 2vw; 
            border: 1px solid #ff9900;
            color: #ff9900;
            text-align: center;
            background-color: #fff;
        }
    }
}
</style>
